import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom'; // <-- Import useLocation from react-router-dom
import { FaSearch, FaChevronDown, FaChevronUp, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import DOMPurify from 'dompurify';

const homes = [
    { id: 1, address: '123 Elm Street', image: 'house1.jpeg', price: '$500,000' },
    { id: 2, address: '456 Maple Avenue', image: 'house2.jpeg', price: '$750,000' },
    { id: 3, address: '789 Oak Boulevard', image: 'house3.jpeg', price: '$1,200,000' },
];

const properties_search_group = [
    {
      id: 1,
      image: 'zillow1.webp',
      address: '260 King St UNIT 953, San Francisco, CA 94107',
      price: '$579,000',
      bed: 1,
      bath: 1,
      sqft: 'NA',
      description: 'Charming & vast corner Jr 1BR Penthouse residence at Beacon, where you can indulge in the lush courtyard and park views. The expansive and open living space creates an airy feel, with shelving in front of the windows offering an opportunity to add decor or a window seat. The chef\'s kitchen boasts contemporary cabinetry, granite counters, and stainless steel appliances. An open walled shelf provides both practicality and creativity, effortlessly dividing the room with a separated bedroom alcove and ample closet space. Relax and unwind in the bathroom with its granite counters and a soothing soaking tub/shower, perfect for rejuvenating after a long day. This home is in pristine condition, located privately at the top corner of the building and offers modern hardwood flooring, additional storage closet, in-unit W&D and earthquake insurance. Experience the 5-star amenities including 24/7 doorman security, heated outdoor pools/spas, sauna, clubhouse with kitchen, gym, business center, and dog park. Easy to lease a parking space onsite. Steps frm Caltrain, Muni, BART & future Central Subway. Short walk to Ferry Bldg/Farmer\'s Market, Embarcadero Waterfront, SOMA, Fidi, Union Square. EZ access to Freeways, tech shuttle/East Bay/Peninsula',
      link: 'https://www.zillow.com/homes/260-King-St-.num.953-San-Francisco,-CA-94107_rb/69819797_zpid/',
      type: 'Condo',
      architecturalStyle: 'Contemporary,Modern/High Tech',
      subType: 'Condominium',
      built: '2004',
      hoaFee: '$854/mo HOA',
      amenities: 'Clubhouse, Dog Park, Fitness Center, Greenbelt, Gym, Park, Recreation Facilities, Sauna, Spa/Hot Tub',
      services: 'Common Areas, Door Person, Earthquake Insurance, Insurance on Structure, Maintenance Structure, Maintenance Grounds, Management, Organized Activities, Pool, Recreation Facility, Security, Sewer, Trash, Water, None',
      association: 'Beacon community Residential Association',
      parking: '1 space; enclosed, interior access, private, side by side, independent, 4.9 acre lot',
      pool: 'pool/spa combo, hot tub',
      views: 'City, City Lights, Downtown, Garden',
      Bedrooms: 1,
      Bathrooms: 1,
      fullBathrooms: 1,
      bedroomFeatures: 'Closet',
      bathroomFeatures: 'Closet, Granite, Soaking Tub, Tub w/Shower Over',
      Flooring: 'Tile, Wood',
      Heating: 'Central',
      Appliances: 'Dishwasher, Disposal, Free-Standing Electric Oven, Free-Standing Electric Range, Free-Standing Refrigerator, Microwave, Washer/Dryer Stacked Included',
      LaundryFeatures: 'Laundry Closet',
      schoolDistrict: 'San Francisco Unified School District',
      nearbySchool1: 'Daniel Webster Elementary School, K-5, Distance: 1.2 mi, GreatSchools rating: 5/10',
      nearbySchool2: 'James Lick Middle School, 6-8, Distance: 3.2 mi, GreatSchools rating: 3/10',
      nearbySchool3: 'Oconnell (John) High School, 9-12, Distance: 1.6 mi, GreatSchools rating: 4/10'
    },
    {
      id: 2,
      image: 'zillow2.webp',
      address: '333 Cherry St #B, San Francisco, CA 94118',
      price: '$616,000',
      bed: 0,
      bath: 1,
      sqft: 'NA',
      description: 'Nestled within one of San Francisco\'s most esteemed neighborhoods, 333 Cherry Street No. B exudes endless charm in your own personal oasis of Presidio Heights. This tranquil studio condominium, tucked away in a secure enclave, is an ideal haven for those seeking a pied--terre experience, and full-time users alike. Discover a private world as you enter through your own discreet pathway, unveiling a residence that seamlessly integrates indoor-outdoor living. This private and secluded retreat offers a thoughtfully remodeled kitchen equipped with high-end stainless steel appliances, gas stove, ample counter space, and granite countertops. Custom-built bookshelves create a tasteful separation from the kitchen and dining areas, complete with Murphy-Bed for an easy transition from living room to bedroom. French doors beckon you to a spacious deck with room for lounging and dining, as well as a lush landscaped backyard. With a 91 Walk Score, experience the luxury of living in close proximity to Presidio trails, a scenic bike path, boutique shops of Sacramento St, Laurel Village, and convenient public transportation. You won\'t find anything like this on one of San Francisco\'s best blocks.',
      link: 'https://www.zillow.com/homedetails/333-Cherry-St-B-San-Francisco-CA-94118/64972281_zpid/',
      type: 'Condo',
      subType: 'Condominium',
      condition: 'updated/remodeled',
      built: '1908',
      hoaFee: '$325/mo HOA',
      services: 'Common Areas, Insurance on Structure, Sewer, Trash, Water',
      views: 'Garden',
      fullBathrooms: 1,
      bathroomFeatures: 'Tub w/Shower Over',
      Flooring: 'Tile, Wood',
      Heating: 'wall furnace',
      Appliances: 'Dishwasher, Disposal, Free-Standing Gas Range, Free-Standing Refrigerator, Microwave',
      kitchen: 'granite counters',
      livingRoom: 'deck attached',
      schoolDistrict: 'San Francisco Unified School District',
      nearbySchool1: 'Sutro Elementary School, K-5, Distance: .8 mi, GreatSchools rating: 9/10',
      nearbySchool2: 'Roosevelt Middle School, 6-8, Distance: .4 mi, GreatSchools rating: 8/10',
      nearbySchool3: 'Wallenberg (Raoul) Traditional High School, 9-12, Distance: .8 mi, GreatSchools rating: 8/10'
    },
    {
      id: 3,
      image: 'zillow3.webp',
      address: '1024 7th Ave, Oakland, CA 94606',
      price: '$829,000',
      bed: 5,
      bath: 4,
      hoaFee: 'No HOA Fee',
      sqft: '2432',
      description: 'Welcome to 1024 7th Ave, a meticulously renovated duplex nestled in the heart of Oakland. This vacant gem offers the perfect blend of modern comfort and convenience, presenting an ideal opportunity for investors and homeowners alike. The upper unit welcomes you with 3+ bedrooms, providing ample space for families or roommates to thrive. Completing the upper level is 2 full baths, elegantly appointed with contemporary fixtures and finishes. The lower unit boasts 2+ bedrooms and 2 baths, offering comfortable accommodations for residents seeking simplicity and charm. This duplex offers a total of 4 bathrooms and spans an impressive 2432 square feet of living space, with a total lot size of 2700 square feet. Beyond the confines of these stylish interiors lies a quaint yard in the back, perfect for outdoor relaxation or entertaining. Nestled in a vibrant neighborhood near lake Merritt, 1024 7th Ave enjoys close proximity to a plethora of amenities and attractions. Commuting is a breeze, with convenient access to 880 Fwy or BART. Don\'t miss the opportunity to own this modern, move in ready duplex in a prime Oakland location.',
      link: 'https://www.zillow.com/homedetails/1024-7th-Ave-Oakland-CA-94606/24757676_zpid/',
      type: 'Multifamily',
      subType: 'duplex',
      materials: 'wood siding',
      built: '1912',
      security: 'double strapped water heater',
      parking: 'off street',
      exterior: 'fenced backyard',
      fullBathrooms: 1,
      bathroomFeatures: 'Tub w/Shower Over',
      Flooring: 'engineered Wood',
      cooling: 'none',
      Heating: 'wall furnace',
      basement: 'unfinished',
      utilities: 'all public utilities',
      schoolDistrict:'Oakland Unified School District',
      nearbySchool1: 'Franklin Elementary School, K-5, Distance: .3 mi, GreatSchools rating: 4/10',
      nearbySchool2: 'Roosevelt Middle School, 6-8, Distance: 1 mi, GreatSchools rating: 4/10',
      nearbySchool3: 'Oakland High School, 9-12, Distance: 1.3 mi, GreatSchools rating: 4/10'
    }
]

const backgroundImages = [
    'url(bg7.png)',
    'url(new2.webp)',
    'url(new4.webp)',
];

const ZillowCopy = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
    const [rankedProperties, setRankedProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [detailedSearchQuery, setDetailedSearchQuery] = useState('');
    const [showDetailedSuggestions, setShowDetailedSuggestions] = useState(false);
    const [questionsAnswers, setQuestionsAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showPreviousQuestions, setShowPreviousQuestions] = useState(false);
    const [streamedContent, setStreamedContent] = useState("");
    const blurTimeoutId = useRef(null); // Use useRef to store the current timeout id

    const eventSourceRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setCurrentBackgroundIndex((currentBackgroundIndex) => (currentBackgroundIndex + 1) % backgroundImages.length);
        }, 5000); // Change image every 10 seconds

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        }
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true); // Start loading
        setShowSuggestions(false);
        try {
          const response = await axios.post(`${props.backendUrl}/all-houses-search`, {
            query: searchQuery,
            propertiesSearchGroup: properties_search_group
          });
          if (response.data.success && response.data.rankings) {
            const rankings = JSON.parse(response.data.rankings);
            setRankedProperties(rankings.rankedProperties);
          }
        } catch (error) {
          console.error('There was an error sending the search query to the backend:', error);
        }
        setIsLoading(false); // End loading after the request is complete
    };      

    const handleDetailedSubmit = async () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }
    
        setIsLoading(true); // Start loading
    
        try {
            const initResponse = await axios.post(`${props.backendUrl}/initialize-stream`, {
                question: detailedSearchQuery,
                propertyDetails: selectedProperty
            });
    
            if (initResponse.data && initResponse.data.streamId) {
                // Set up a new question-answer pair with an empty answer
                setQuestionsAnswers(prev => [...prev, { question: detailedSearchQuery, answer: '', isOpen: true }]);
    
                eventSourceRef.current = new EventSource(`${props.backendUrl}/selected-house-query?streamId=${initResponse.data.streamId}`);
    
                eventSourceRef.current.onmessage = (event) => {
                    const newChunk = JSON.parse(event.data);
    
                    if (newChunk && newChunk.content) {
                        setQuestionsAnswers(prev => {
                            const lastIdx = prev.length - 1;
                            const lastQa = prev[lastIdx];
                            const updatedAnswer = lastQa.answer + newChunk.content;
                            const updatedQa = { ...lastQa, answer: updatedAnswer };
                            return [...prev.slice(0, lastIdx), updatedQa];
                        });
                    }
    
                    setIsLoading(false);
                };
    
                eventSourceRef.current.onerror = (error) => {
                    console.error('EventSource failed:', error);
                    setIsLoading(false);
                    eventSourceRef.current.close();
                };
            }
        } catch (error) {
            console.error('There was an error initializing the stream:', error);
            setIsLoading(false);
        }
    
        setTimeout(() => {
            // Check if the "Show Previous Questions" button exists
            const previousQuestionsButton = document.querySelector(".toggle-previous-questions-button");
            const lastQuestionAnswerCard = document.querySelector(".answers-container");
          
            // If the button exists, scroll to it; otherwise, scroll to the last question-answer card
            const targetElement = previousQuestionsButton || lastQuestionAnswerCard;
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 100);              
        setDetailedSearchQuery('');
    };
    
    

    const toggleAnswerVisibility = (index) => {
        const updatedQuestionsAnswers = questionsAnswers.map((item, idx) => {
            if (idx === index) {
                return { ...item, isOpen: !item.isOpen };
            }
            return item;
        });
        setQuestionsAnswers(updatedQuestionsAnswers);
    };

    const handleInputBlur = () => {
        blurTimeoutId.current = setTimeout(() => {
            setShowSuggestions(false);
        }, 100); // Delay the hiding of suggestions
    };

    const handleInputBlur2 = () => {
        blurTimeoutId.current = setTimeout(() => {
            setShowDetailedSuggestions(false);
        }, 100); // Delay the hiding of suggestions
    };

    // Clear the timeout if a suggestion is clicked
    const handleSuggestionClick = (suggestion) => {
        clearTimeout(blurTimeoutId.current);
        setSearchQuery(suggestion); // Update the main search query
        setShowSuggestions(false); // Hide the main search suggestions
    };

    const formatDetails = ({ bed, bath, sqft, built }) => {
        // Define an array to hold the details and only include built if other details exist
        let details = [];
      
        if (bed !== 'NA') {
          details.push(`${bed} bds`);
        }
      
        if (bath !== 'NA') {
          details.push(`${bath} ba`);
        }
      
        if (sqft !== 'NA') {
          details.push(`${sqft} sqft`);
        }
      
        // Add the built string only if there are other details to show before it
        if (built && details.length > 0) {
          details.push(`${built}`);
        }
      
        return details.join(' | '); // This will only add dividers between existing elements
    };
         
    const handlePropertySelect = (property) => {
        setSelectedProperty(property);
        window.scrollTo(0, 0); // This will scroll the page to the top
    };
       

    const handleDetailedInputFocus = () => {
        setShowDetailedSuggestions(true); // Show suggestions when the input is focused
    };

    const handleDetailedSuggestionClick = (suggestion) => {
        clearTimeout(blurTimeoutId.current);
        setDetailedSearchQuery(suggestion); // Update the detailed search query
        setShowDetailedSuggestions(false); // Hide the detailed search suggestions
    };

    const togglePreviousQuestionsVisibility = () => {
        setShowPreviousQuestions(!showPreviousQuestions);
        setTimeout(() => {
            const previousQuestionsButton = document.querySelector(".toggle-previous-questions-button");
            const previousQuestions = document.querySelector(".previous-questions:last-child");
          
            const targetElement = previousQuestions || previousQuestionsButton;
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 100);   
    };

    const handleKeyPressMainSearch = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    // Function to handle "Enter" key press for detailed search
    const handleKeyPressDetailedSearch = (event) => {
        if (event.key === 'Enter') {
            handleDetailedSubmit(event);
        }
    };

    const createMarkup = (htmlContent) => {
        return { __html: DOMPurify.sanitize(htmlContent) };
    };      

    if (selectedProperty) {
        // Inside your selectedProperty return block
        return (
            <div className="zillowCopy selected-property-page">
                <button onClick={() => setSelectedProperty(null)} className="back-button">
                    <FaArrowLeft />
                    <span>Back to results</span>
                </button>
                <div className="search-container2">
                    <input
                        type="text"
                        placeholder="Ask about schools, HOA, zoning, or anything regarding this property..."
                        className="search-input"
                        value={detailedSearchQuery}
                        onChange={(e) => setDetailedSearchQuery(e.target.value)}
                        onKeyDown={handleKeyPressDetailedSearch} // enter works
                        onFocus={handleDetailedInputFocus}
                        onBlur={handleInputBlur2}
                    />
                    <button onClick={handleDetailedSubmit} className="search-button">
                        <FaSearch size="24" />
                    </button>
                    {/* 
                    {showDetailedSuggestions && (
                        <div className="suggestions-bubble">
                            <div className="suggestion message">Try asking about:</div>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Can I rent out a portion of this home as a short-term vacation rental?')}>Can I rent out a portion of this home as a short-term vacation rental?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Can I build a 2 story ADU on this property?')}>Can I build a 2 story ADU on this property?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Does the local highschool offer AP classes?')}>Does the local highschool offer AP classes?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Is this property in a flood zone?')}>Is this property in a flood zone?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('What are the property taxes like?')}>What are the property taxes like?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Are there any zoning restrictions I should be aware of?')}>Are there any zoning restrictions I should be aware of?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Is there a music program at the local highschool?')}>Is there a music program at the local highschool?</button>
                            <button className="suggestion" onClick={() => handleDetailedSuggestionClick('Are there any tax incentives for installing solar panels or making green renovations in this home?')}>Are there any tax incentives for installing solar panels or making green renovations in this home?</button>
                        </div>
                    )}
                    */}
                </div>
                <div className="property-display-wrapper">
                    <section className="property-image-section">
                    <img src={selectedProperty.image} alt="Selected Property" className="selected-property-image"/>
                    </section>
                    <section className="property-info-section">
                    <h1 className="property-price">{selectedProperty.price}</h1>
                    <p className="property-address">{selectedProperty.address}</p>
                    <p className="school-district">{selectedProperty.schoolDistrict}</p>
                    {/* Information bubbles will go here */}
                    <div className="info-bubbles">
                        {/* Map over the info you want to display in bubbles */}
                        {/* Replace this with actual data mapping */}
                        <div className="info-bubble">{selectedProperty.type}</div>
                        <div className="info-bubble">Built in {selectedProperty.built}</div>
                        <div className="info-bubble">{selectedProperty.hoaFee} </div>
                        <div className="info-bubble">{selectedProperty.bed} beds</div>
                        <div className="info-bubble">{selectedProperty.bath} baths</div>
                        <div className="info-bubble">{selectedProperty.sqft} sqft</div>
                        {/* ... other bubbles */}
                    </div>
                    </section>
                </div>

                <div id="answers-container" className="answers-container">
                    {questionsAnswers.length > 0 && (
                        <>
                            {/* Render only the most recent question and answer by default */}
                            <div className="question-answer-card">
                                <div className="question-section" onClick={() => toggleAnswerVisibility(questionsAnswers.length - 1)}>
                                    <p className="question-text">{questionsAnswers[questionsAnswers.length - 1].question}</p>
                                    <div className="question-toggle">
                                        {questionsAnswers[questionsAnswers.length - 1].isOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </div>
                                </div>
                                {questionsAnswers[questionsAnswers.length - 1].isOpen && (
                                    <div className="answer-section">
                                        <div
                                            className="answer-text"
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(questionsAnswers[questionsAnswers.length - 1].answer) }}
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Button to toggle previous questions */}
                            {questionsAnswers.length > 1 && (
                                <button onClick={togglePreviousQuestionsVisibility} className="toggle-previous-questions-button">
                                    {showPreviousQuestions ? "Hide Previous Questions" : "Show Previous Questions"} 
                                    <FaChevronDown />
                                </button>
                            )}

                            {/* Conditionally render previous questions if showPreviousQuestions is true */}
                                {showPreviousQuestions && questionsAnswers.slice(0, -1).reverse().map((qa, index) => (
                                    <div className='previous-questions'>
                                        <div key={index} className="question-answer-card">
                                            <div className="question-section" onClick={() => toggleAnswerVisibility(index)}>
                                                <p className="question-text">{qa.question}</p>
                                                <div className="question-toggle">
                                                    {qa.isOpen ? <FaChevronUp /> : <FaChevronDown />}
                                                </div>
                                            </div>
                                            {qa.isOpen && (
                                                <div className="answer-section">
                                                    <div
                                                        className="answer-text"
                                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(qa.answer) }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
                <div className="streamed-content" dangerouslySetInnerHTML={{ __html: streamedContent }}></div>

                <div className="streamed-content">{streamedContent}</div>

                {isLoading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}



                {/* Search container*/}

            </div>
        );
  
    }    

    return (
      <div className="zillowCopy">
        <section className="search-section" style={{
            backgroundImage: backgroundImages[currentBackgroundIndex],
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            height: '450px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            transition: 'background-image 1s ease-in-out',
        }}>
            <h1 className="search-title">Smart Search. Detailed Results. Your Perfect Home.</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Tell me about the home you're dreaming of - any area, feature, or detail!"
                    className="search-input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyPressMainSearch} // enter works
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={handleInputBlur} 
                />
                <button onClick={handleSubmit} className="search-button">
                    <FaSearch size="24" />
                </button>
                {/*
                {showSuggestions && (
                    <div className="suggestions-bubble">
                        <div className="suggestion message">Other people searched for:</div>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Homes in family-friendly neighborhoods with top-rated schools')}>Homes in family-friendly neighborhoods with top-rated schools</button>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Pet-friendly apartments with a park nearby')}>Pet-friendly apartments with a park nearby</button>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Eco-friendly houses near Central Park, NY')}>Eco-friendly houses near Central Park, NY</button>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Newly built properties with high-tech features in Silicon Valley')}>Newly built properties with high-tech features in Silicon Valley</button>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Waterfront properties with docks in Fort Lauderdale')}>Waterfront properties with docks in Fort Lauderdale</button>
                        <button className="suggestion" onClick={() => handleSuggestionClick('Condos with a city view and easy transit access')}>Condos with a city view and easy transit access</button>
                    </div>
                )}
                */}
            </div>
        </section>

        {rankedProperties.length > 0 ? (
                <section className="trending-homes">
                    <h2 className="trending-title">Best Matches</h2>
                    <p className='trending-subtitle'>All results are ordered by relevancy using AI</p>
                    <div className="home-list">
                        {rankedProperties.map((property, index) => {
                            const propertyDetails = properties_search_group.find(p => p.id === property.id);
                            return (
                                <div key={property.id} className="home-card2" onClick={() => handlePropertySelect(propertyDetails)}>
                                <div className="home-image-container">
                                    <img src={propertyDetails?.image} alt={`Property ${index}`} className="home-image" />
                                    <div className="explanation-overlay">
                                    <p>{property.explanation}</p>
                                    </div>
                                </div>
                                <div className="home-info">
                                    <p className="home-price">{propertyDetails?.price}</p>
                                    <div className="home-details">
                                    {formatDetails(propertyDetails)}
                                    </div>
                                    <p className="home-address">{propertyDetails?.address}</p>
                                </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            
            ) : (

                <section className="trending-homes">
                    <h2 className="trending-title">Trending Homes Near You</h2>
                    <p className='trending-subtitle'>Viewed and saved the most in the area over the past 24 hours</p>
                    <div className="home-list">
                        {properties_search_group.slice(0, 3).map((property, index) => ( // Only show the first 3 homes
                        <div key={property.id} className="home-card" onClick={() => handlePropertySelect(property)}>
                            <div className="home-image-container">
                            <img src={`${props.frontendUrl}/${property.image}`} alt={`${props.frontendUrl}/${property.image}`} className="home-image" />
                            </div>
                            <div className="home-info">
                            <p className="home-price">{property.price}</p>
                            <div className="home-details">
                                {formatDetails(property)}
                            </div>
                            <p className="home-address">{property.address}</p>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="more-homes-button-container">
                        <button className="more-homes-button">More recommended homes</button>
                    </div>
                </section>
            )
        }

        {isLoading && (
            <div className="loading-overlay">
                <div className="spinner"></div>
            </div>
        )}

      </div>
    );
  };
  
  export default ZillowCopy;
  
