import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaSearch, FaChevronDown, FaChevronUp, FaArrowLeft } from 'react-icons/fa';
import { Outlet, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

const properties = [
    {
      id: 1,
      address: '260 King St UNIT 953, San Francisco, CA 94107',
    },
    {
      id: 2,
      address: '333 Cherry St #B, San Francisco, CA 94118',
    },
    {
      id: 3,
      address: '1024 7th Ave, Oakland, CA 94606',
      
    }
  ]

const AdminHistory = (props) => {
  const { account_id } = useParams();
  const [historyData, setHistoryData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const [userList, setUserList] = useState([]);
  const [questionVisibility, setQuestionVisibility] = useState({});
  const [searchVisibility, setSearchVisibility] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${props.backendUrl}/user-list`);
        setUserList(response.data);
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    fetchUserList();
  }, []);

  const formatDate = (datetime) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(datetime).toLocaleDateString('en-US', options);
  };

  const toggleQuestionVisibility = (index) => {
    setQuestionVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleSearchVisibility = (index) => {
    setSearchVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const goBack = () => {
    navigate(`/property-search/${account_id}`);
  };

  useEffect(() => {
    if (selectedUser) {
      const fetchData = async () => {
        try {
          // Fetch data from backend route for the selected user
          const response = await axios.post(`${props.backendUrl}/user-history`, {
            account_id: selectedUser,
          });
          setHistoryData(response.data);
          // Initialize visibility states
          const initialQuestionVisibility = {};
          const initialSearchVisibility = {};
          response.data.questions.forEach((_, index) => {
            initialQuestionVisibility[index] = true;
          });
          response.data.searches.forEach((_, index) => {
            initialSearchVisibility[index] = true;
          });
          setQuestionVisibility(initialQuestionVisibility);
          setSearchVisibility(initialSearchVisibility);
          console.log(response.data.searches)
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    }
  }, [selectedUser]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div className='history-container'>
      <div className="view-question-history-button">
          <Link className='history-button' to={`${props.frontendUrl}/property-search/${account_id}`}>Property Search</Link>
          <Link className='log-out-button' to={`${props.frontendUrl}`}>Log Out</Link>
      </div>
      <h2 className='history-title'>View User History (Admin View)</h2>
      <div>
        <label htmlFor="userSelect">Select User:</label>
        <select className='select-user' id="userSelect" value={selectedUser} onChange={handleUserChange}>
          <option value="">-- Select User --</option>
          {userList.map((user) => (
            <option key={user.id} value={user.id}>{user.email}</option>
          ))}
        </select>
      </div>

      {historyData && (
        <>
          <div className='summary-container'>
          <h3 className='summary-title'>User Interest Summary</h3>
          <p><strong>Zip Codes of Interest:</strong> {historyData.zip_codes_of_interest.join(', ')}</p>
          <p><strong>Range of House Prices of Interest:</strong> ${historyData.range_of_house_prices_of_interest[0]} - ${historyData.range_of_house_prices_of_interest[1]}</p>
          <p><strong>Range of Square Footage of Interest:</strong> {historyData.range_of_square_footage_of_interest[0]} sqft - {historyData.range_of_square_footage_of_interest[1]} sqft</p>
        </div>
          <h2 className='question-history-title'>Question History</h2>
          <ul>
            {historyData.questions.map((question, index) => (
              <div className="previous-questions" key={`question_${index}`}>
                <div className="question-answer-card">
                  <div className="question-section" onClick={() => toggleQuestionVisibility(index)}>
                    <p className="question-text">{question[1]}</p>
                    <p>{question[4]}</p>
                    <p>{formatDate(question[3])}</p>
                    <div className="question-toggle">
                      {questionVisibility[index] ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {questionVisibility[index] && (
                    <div className="answer-section">
                      <div className="answer-text"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question[2]) }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ul>

          <h2>Search History</h2>
          <ul>
            {historyData.searches.map((search, index) => (
              <div className="previous-questions" key={`search_${index}`}>
                <div className="question-answer-card">
                  <div className="question-section" onClick={() => toggleSearchVisibility(index)}>
                    <p className="question-text">{search[1]}</p>
                    <p>{formatDate(search[3])}</p>
                    <div className="question-toggle">
                      {searchVisibility[index] ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {searchVisibility[index] && (
                    <div className="answer-section">
                      {/* Parse the JSON string in rankedProperties before accessing its properties */}
                      {search[2] && (
                        <ul>
                          {JSON.parse(search[2]).rankedProperties.map((property, idx) => (
                            <li key={idx}>
                              <p><strong>Address:</strong> {properties.find(item => item.id === property.id)?.address}</p>
                              <p><strong>Relevance Score:</strong> {property.relevanceScore}</p>
                              <p><strong>Explanation:</strong> {property.explanation}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AdminHistory;
