// Signup.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

const Signup = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signUpSuccessful, setSignUpSuccessful] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [error, setError] = useState(null);
    const [feedback, setFeedback] = useState('');

    const navigate = useNavigate();

    const handleChange = (event) => {
        if(event.target.name === "email") setEmail(event.target.value);
        if(event.target.name === "password") setPassword(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${props.backendUrl}/signup`, { email, password });
            setAccountId(response.data); // Make sure 'account_id' is the correct property
            setFeedback(`Welcome ${email}, you have successfully signed up. Redirecting you to the property search page...`);
            setTimeout(() => {
                navigate(`/property-search/${response.data}`);
            }, 1000);
        } catch (err) {
            setError(err.response.data.error);
        }
    }

    const handleGoogleSuccess = async (response) => {
        console.log(response);
        try {
            const res = await axios.post(`${props.backendUrl}/auth/google`, {
                token: response.credential,
            });
            console.log('Google Signup Response:', res.data);
            if (res.data.account_id) {
                setFeedback(`Welcome, you have successfully signed up. Redirecting you to the property search page...`);
                setTimeout(() => {
                    navigate(`/property-search/${res.data.account_id}`);
                }, 1000);
            } else {
                setError('No account ID returned from the server.');
            }
        } catch (err) {
            console.error('Google Signup Error:', err);
            setError('An error occurred with Google signup. Please try again.');
        }
    };

    const handleGoogleFailure = (response) => {
        console.error('Google Signup failed:', response);
        setError('Google signup failed. Please try again.');
    };

    return (
        <div className="signup-container"> {/* Add custom class for container */}
            <div className="google-signup">
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onFailure={handleGoogleFailure}
                    useOneTap
                />
            </div>
            <p className="separator">or</p>
            <form className='signup-form' onSubmit={handleSubmit}> {/* Add custom class for form */}
                <input className='signup-input' name="email" type="email" placeholder="Email" onChange={handleChange} /> {/* Add custom class for input */}
                <input className='signup-input' name="password" type="password" placeholder="Password" onChange={handleChange} /> {/* Add custom class for input */}
                <button className='signup-button' type="submit">Sign Up</button> {/* Add custom class for button */}
                {error && <p className='error-message'>{error}</p>} {/* Add custom class for error message */}
                {feedback && <p className='feedback-message'>{feedback}</p>} {/* Add custom class for feedback message */}
            </form>
        </div>
    );
}

export default Signup;
