// Login.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [feedback, setFeedback] = useState('');

    const navigate = useNavigate();
    
    const handleChange = (event) => {
        if(event.target.name === "email") setEmail(event.target.value);
        if(event.target.name === "password") setPassword(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${props.backendUrl}/login`, { email, password });
            console.log('Login Response:', response.data); // Add this line to log the response
            if (response.data.account_id) {
                setFeedback(`Welcome ${email}, you have successfully logged in. Redirecting you to the property search page...`);
                setTimeout(() => {
                    navigate(`/property-search/${response.data.account_id}`);
                }, 1000);
            } else {
                setError('No account ID returned from the server.');
            }
        } catch (err) {
            console.error('Login Error:', err); // Add this line to log any errors
            if (err.response) {
                setError(err.response.data.error);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        console.log(response);
        try {
            const res = await axios.post(`${props.backendUrl}/auth/google`, {
                token: response.credential,
            });
            console.log('Google Login Response:', res.data);
            if (res.data.account_id) {
                setFeedback(`Welcome, you have successfully logged in. Redirecting you to the property search page...`);
                setTimeout(() => {
                    navigate(`/property-search/${res.data.account_id}`);
                }, 1000);
            } else {
                setError('No account ID returned from the server.');
            }
        } catch (err) {
            console.error('Google Login Error:', err);
            setError('An error occurred with Google login. Please try again.');
        }
    };

    const handleGoogleFailure = (response) => {
        console.error('Google Login failed:', response);
        setError('Google login failed. Please try again.');
    };

    return (
        <div className="login-container"> {/* Add custom class for container */}
            <div className="google-login">
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onFailure={handleGoogleFailure}
                    useOneTap
                />
            </div>
            <p className="separator">or</p>
            <form className='login-form' onSubmit={handleSubmit}> {/* Add custom class for form */}
                <input className='login-input' name="email" type="email" placeholder="Email" onChange={handleChange} value={email} /> {/* Add custom class for input */}
                <input className='login-input' name="password" type="password" placeholder="Password" onChange={handleChange} value={password} /> {/* Add custom class for input */}
                <button className='login-button' type="submit">Login</button> {/* Add custom class for button */}
                {error && <p className='error-message'>{error}</p>} {/* Add custom class for error message */}
                {feedback && <p className='feedback-message'>{feedback}</p>} {/* Add custom class for feedback message */}
            </form>
        </div>
    );
}

export default Login;
