import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: 4/3/24</p>
      
      <p>Your privacy is important to us. It is Elwing's policy to respect your privacy regarding any information we may collect from you across our website, http://192.168.68.102:3000/, and other sites we own and operate.</p>
      
      <h2>1. Information We Collect</h2>
      <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent.</p>
      
      <h2>2. Use of Information</h2>
      <p>We use the information we collect for providing and improving our services. We will not share your information with any third parties, except as required by law.</p>
      
      <h2>3. Data Retention</h2>
      <p>We only retain collected information for as long as necessary to provide you with your requested service.</p>
      
      <h2>4. Cookies</h2>
      <p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site.</p>
      
      <h2>5. Third-Party Services</h2>
      <p>We may employ third-party companies and individuals on our websites—for example, analytics providers and content partners. We grant these third parties access to selected information to perform specific tasks on our behalf.</p>
      
      <h2>6. Your Rights</h2>
      <p>You have the right to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
      
      <h2>7. Changes to Privacy Policy</h2>
      <p>We reserve the right to change our privacy policy at any time.</p>
      
      <p>If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
    </div>
  );
};

export default PrivacyPolicy;
