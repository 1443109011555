import React from 'react';
import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginPage = ({ backendUrl, frontendUrl }) => {
    const handleLoginSuccess = (response) => {
        console.log(response);
        fetch(`${backendUrl}/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential }),
        })
        .then(res => res.json())
        .then(data => {
            // Handle response from backend
            console.log(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleLoginFailure = (response) => {
        console.error('Login failed:', response);
    };

    return (
        <div>
            <h2>Login with Google</h2>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                useOneTap
            />
        </div>
    );
};

export default GoogleLoginPage;
