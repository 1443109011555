import { GoogleOAuthProvider } from '@react-oauth/google';

import Layout from "./Layout";
import NoPage from "./NoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import './App.css';
import ZillowCopy from "./ZillowCopy";
import Login from "./Login";
import Signup from "./Signup";
import TermsOfService from "./TOS";
import PrivacyPolicy from "./PrivacyPolicy";
import PropertySearch from "./PropertySearch";
import History from './History';
import AdminHistory from './AdminHistory';
import TestComponent from './TestComponent';
import GoogleLoginPage from './GoogleLogin';
import AddUriForm from './AddUriForm';
import QueryForm from './QueryForm';
import TestMirielApi from './TestMirielApi';

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          backendUrl: 'https://elwing.ai/api', 
          frontendUrl: 'https://elwing.ai',
        };  
    }   
  
    
    render() {
        return (
          <GoogleOAuthProvider clientId="62123385258-gtoansf53mttbvcdmurk5311oc4h0ntl.apps.googleusercontent.com">
            <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Layout backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />}>
                    <Route index element={<ZillowCopy backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/property-search/:account_id" element={<PropertySearch backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/google-login" element={<GoogleLoginPage backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/login" element={<Login backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/signup" element={<Signup backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/test" element={<TestComponent backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/add-uri" element={<AddUriForm backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/query" element={<QueryForm backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/history/:account_id" element={<History backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/admin-history/:account_id" element={<AdminHistory backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/tos" element={<TermsOfService backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/test-miriel-api" element={<TestMirielApi backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy backendUrl={this.state.backendUrl} frontendUrl={this.state.frontendUrl} />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </BrowserRouter>
          </GoogleOAuthProvider>
          );
        }
  }