import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaSearch, FaChevronDown, FaChevronUp, FaArrowLeft } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

const History = (props) => {
  const { account_id } = useParams();
  const [historyData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionVisibility, setQuestionVisibility] = useState({});
  const [searchVisibility, setSearchVisibility] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (account_id === 'XXBWGWI') {
      navigate(`/admin-history/${account_id}`);
      return; // Prevent further execution if redirecting
    }

    const fetchData = async () => {
      try {
        // Fetch data from backend route
        const response = await axios.post(`${props.backendUrl}/user-history`, {
          account_id: account_id,
        });
        setHistoryData(response.data);
        setLoading(false);

        // Initialize visibility states
        const initialQuestionVisibility = {};
        const initialSearchVisibility = {};
        response.data.questions.forEach((_, index) => {
          initialQuestionVisibility[index] = true;
        });
        response.data.searches.forEach((_, index) => {
          initialSearchVisibility[index] = true;
        });
        setQuestionVisibility(initialQuestionVisibility);
        setSearchVisibility(initialSearchVisibility);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [account_id, props.backendUrl, navigate]);

  const formatDate = (datetime) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(datetime).toLocaleDateString('en-US', options);
  };

  const toggleQuestionVisibility = (index) => {
    setQuestionVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleSearchVisibility = (index) => {
    setSearchVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='answers-container'>
      <h2>User Email: {historyData.user_email}</h2>
      
      <h2>Question History</h2>
      {historyData.questions.length === 0 ? (
        <p>No questions found.</p>
      ) : (
        <ul>
          {historyData.questions.map((question, index) => (
            <div className="previous-questions" key={`question_${index}`}>
              <div className="question-answer-card">
                <div className="question-section" onClick={() => toggleQuestionVisibility(index)}>
                  <p className="question-text">{question[1]}</p>
                  <p>{question[4]}</p>
                  <p>{formatDate(question[3])}</p>
                  <div className="question-toggle">
                    {questionVisibility[index] ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {questionVisibility[index] && (
                  <div className="answer-section">
                    <div className="answer-text"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question[2]) }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </ul>
      )}

      <h2>Search History</h2>
      {historyData.searches.length === 0 ? (
        <p>No searches found.</p>
      ) : (
        <ul>
          {historyData.searches.map((search, index) => (
            <div className="previous-questions" key={`search_${index}`}>
              <div className="question-answer-card">
                <div className="question-section" onClick={() => toggleSearchVisibility(index)}>
                  <p className="question-text">{search[1]}</p>
                  <p>{formatDate(search[3])}</p>
                  <div className="question-toggle">
                    {searchVisibility[index] ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {searchVisibility[index] && (
                  <div className="answer-section">
                    <p className="answer-text">{search[2]}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default History;
