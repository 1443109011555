import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p>Last updated: 4/3/24</p>
      
      <h2>1. Agreement to Terms</h2>
      <p>By accessing our website, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you do not have permission to access our website.</p>
      
      <h2>2. Intellectual Property</h2>
      <p>The content, features, and functionality are and will remain the exclusive property of Elwing and its licensors.</p>
      
      <h2>3. Links to Other Websites</h2>
      <p>Our service may contain links to third-party web sites or services that are not owned or controlled by Elwing.</p>
      
      <h2>4. Termination</h2>
      <p>We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      
      <h2>5. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of San Francisco, without regard to its conflict of law provisions.</p>
      
      <h2>6. Changes to Terms</h2>
      <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>
      
      <p>If you have any questions about these Terms, please contact us.</p>
    </div>
  );
};

export default TermsOfService;
