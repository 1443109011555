import React, { useState } from 'react';
import axios from 'axios';

const TestComponent = (props) => {
    const [message, setMessage] = useState('');

    const testBackend = async () => {
        try {
            const response = await axios.get(`${props.backendUrl}/test`);
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error: ' + error.message);
        }
    };

    return (
        <div>
            <button onClick={testBackend}>Test Backend</button>
            <p>{message}</p>
        </div>
    );
};

export default TestComponent;
