import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom'; // <-- Import useLocation from react-router-dom

const Layout = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 768; // assuming width <= 768 is a mobile device

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const location = useLocation();  // <-- get the current location

  // Check if the current path is the root path (i.e., HomePage is being rendered)
  const isHomePage = location.pathname === '/';

  return (
        <div>
            <header className="header">
                <div className="logo-section">
                    <Link to="/">
                        <img src="/elwing3.png" alt="Logo" className="logo" />
                    </Link>
                    <Link className='logo-text-link' to="/">
                        <h1 className="logo-text">Elwing</h1> {/* Changed from <p> to <h1> for semantic and accessibility reasons, and added a class for styling */}
                    </Link>
                </div>
                <div className="auth-section"> {/* Renamed for clarity */}
                    {isHomePage ? (
                            <>
                                <li className="li button-margin">
                                    <Link to="/login" className='blue-button-sign-in'>Sign In</Link>
                                </li>
                                <li className="li">
                                    <Link to="/signup" className='blue-button-get-started'>Get Started</Link>
                                </li>
                            </>
                        ) : (
                            <p></p>
                    )}
                </div>
            </header>
            <Outlet />
            <footer className="footer" role="contentinfo">
            <div className="left-footer-section">
                <div className={isHomePage ? "logo-text" : 'logo-text-blue'}>
                    <span className="footer-logo-text">Elwing</span>
                    <div className="footer-copyright">© 2023 Elwing, Inc.</div>
                </div>
            </div>
            <div className="footer-section">
                <h2 className="section-title">Features</h2>
                <ul>
                    <li>Convenience and Centralization</li>
                    <li>Efficiency and Automation</li>
                    <li>Real-time Updates</li>
                    <li>AI-Driven Estimates</li>
                    <li>Dynamic Estimate Editing</li>
                    <li>Automated Approvals</li>
                </ul>
            </div>
            <div className="footer-section">
                <h2 className="section-title">Resources</h2>
                <ul>
                    <li>User Guides</li>
                    <li>FAQs</li>
                    <li>Getting Started</li>
                    <li>Troubleshooting</li>
                </ul>
            </div>
            <div className="footer-section">
                <h2 className="section-title">About Us</h2>
                <ul>
                    <li>Company History</li>
                    <li>Our Team</li>
                    <li>Testimonials</li>
                    <li>Careers</li>
                </ul>
            </div>
            <div className="footer-section">
                <h2 className="section-title">Support</h2>
                <ul>
                    <li>Email: support@SuperCMA.com</li>
                    <li>Contact: +1 (310)-990-4375</li>
                </ul>
            </div>
            <div className="social-footer-section">
                <h2 className="section-title">Socials</h2>
                <div className="social-icons-row">
                    <img src='/facebook.svg' alt='Facebook' />
                    <img src='/instagram.svg' alt='Instagram' />
                    <img src='/linkedin.svg' alt='LinkedIn' />
                </div>
                <div className="social-icons-row">
                    <img src='/twitter.svg' alt='Twitter' />
                    <img src='/youtube.svg' alt='YouTube' />
                    <img src='/google-plus.svg' alt='Google Plus' />
                </div>
            </div>
        </footer>
        </div>
    );

}

export default Layout;
