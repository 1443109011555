import React, { useState } from 'react';
import axios from 'axios';

const AddUriForm = (props) => {
    const [uri, setUri] = useState('');
    const [recursionDepth, setRecursionDepth] = useState(0);
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${props.backendUrl}/miriel/adduri`, {
                uris: [uri],
                recursion_depth: recursionDepth
            });
            setStatus(response.data.status || 'URI added successfully!');
        } catch (error) {
            setStatus(`Error: ${error.response.data.error || error.message}`);
        }
    };

    return (
        <div>
            <h2>Add URI to Miriel</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>URI:</label>
                    <input 
                        type="text" 
                        value={uri} 
                        onChange={(e) => setUri(e.target.value)} 
                        placeholder="Enter URI" 
                        required 
                    />
                </div>
                <div>
                    <label>Recursion Depth:</label>
                    <input 
                        type="number" 
                        value={recursionDepth} 
                        onChange={(e) => setRecursionDepth(Number(e.target.value))} 
                        min="0" 
                        placeholder="Enter recursion depth" 
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
            {status && <p>{status}</p>}
        </div>
    );
};

export default AddUriForm;
