import React, { useState } from 'react';

function QueryForm({ backendUrl }) {
    const [inputValue, setInputValue] = useState('');
    const [data, setData] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        setData(null);
        setErrorText('');

        const url = `${backendUrl}/miriel/query`;
        const postData = { query: inputValue };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                },
                body: JSON.stringify(postData)
            });
            const result = await response.json();
            setData(result);
            console.log("Got a response", result);
        } catch (error) {
            console.error('Error:', error);
            setErrorText("Error Searching");
        } finally {
            setIsSubmitted(false);
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Enter your question here" onChange={handleInputChange} />
                <button type="submit" disabled={isSubmitted}>Submit</button>
            </form>
            {data && (
                <div>
                    <span style={{"whiteSpace": "pre-line"}}>{data.llm_result}</span>
                </div>
            )}
            {errorText && (
                <div>{errorText}</div>
            )}
        </div>
    );
}

export default QueryForm;
